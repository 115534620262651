// import React from 'react';
import Loadable from 'react-loadable'

// import CodeEditors from './views/Editors/CodeEditors'
import DefaultLayout from '../containers/DefaultLayout/DefaultLayout';

import Login from '../views/Login/Login'
import Loading from '../components/Loading/Loading'

// function Loading() {
//   return <div>Loading...</div>;
// }


// const Compose = Loadable({
//   loader: () => import('./views/Apps/Email/Compose'),
//   loading: Loading,
// });

// const Inbox = Loadable({
//   loader: () => import('./views/Apps/Email/Inbox'),
//   loading: Loading,
// });

// const Message = Loadable({
//   loader: () => import('./views/Apps/Email/Message'),
//   loading: Loading,
// });

// const Invoice = Loadable({
//   loader: () => import('./views/Apps/Invoicing/Invoice'),
//   loading: Loading,
// });

// const Breadcrumbs = Loadable({
//   loader: () => import('./views/Base/Breadcrumbs'),
//   loading: Loading,
// });

// const Cards = Loadable({
//   loader: () => import('./views/Base/Cards'),
//   loading: Loading,
// });

// const Carousels = Loadable({
//   loader: () => import('./views/Base/Carousels'),
//   loading: Loading,
// });

// const Collapses = Loadable({
//   loader: () => import('./views/Base/Collapses'),
//   loading: Loading,
// });

// const Dropdowns = Loadable({
//   loader: () => import('./views/Base/Dropdowns'),
//   loading: Loading,
// });

// const Jumbotrons = Loadable({
//   loader: () => import('./views/Base/Jumbotrons'),
//   loading: Loading,
// });

// const ListGroups = Loadable({
//   loader: () => import('./views/Base/ListGroups'),
//   loading: Loading,
// });

// const Navbars = Loadable({
//   loader: () => import('./views/Base/Navbars'),
//   loading: Loading,
// });

// const Navs = Loadable({
//   loader: () => import('./views/Base/Navs'),
//   loading: Loading,
// });

// const Paginations = Loadable({
//   loader: () => import('./views/Base/Paginations'),
//   loading: Loading,
// });

// const Popovers = Loadable({
//   loader: () => import('./views/Base/Popovers'),
//   loading: Loading,
// });

// const ProgressBar = Loadable({
//   loader: () => import('./views/Base/ProgressBar'),
//   loading: Loading,
// });

// const Switches = Loadable({
//   loader: () => import('./views/Base/Switches'),
//   loading: Loading,
// });

// const Tabs = Loadable({
//   loader: () => import('./views/Base/Tabs'),
//   loading: Loading,
// });

// const Tooltips = Loadable({
//   loader: () => import('./views/Base/Tooltips'),
//   loading: Loading,
// });

// const BrandButtons = Loadable({
//   loader: () => import('./views/Buttons/BrandButtons'),
//   loading: Loading,
// });

// const ButtonDropdowns = Loadable({
//   loader: () => import('./views/Buttons/ButtonDropdowns'),
//   loading: Loading,
// });

// const ButtonGroups = Loadable({
//   loader: () => import('./views/Buttons/ButtonGroups'),
//   loading: Loading,
// });

// const Buttons = Loadable({
//   loader: () => import('./views/Buttons/Buttons'),
//   loading: Loading,
// });

// const LoadingButtons = Loadable({
//   loader: () => import('./views/Buttons/LoadingButtons'),
//   loading: Loading,
// });

// const Charts = Loadable({
//   loader: () => import('./views/Charts'),
//   loading: Loading,
// });

const Dashboard = Loadable({
  loader: () => import('../views/Dashboard/Dashboard'),
  loading: Loading
});

const EditContent = Loadable({
  loader: () => import('../views/EditContent/EditContent'),
  loading: Loading
});

const EditHome = Loadable({
  loader: () => import('../views/EditContent/EditHome/EditHome'),
  loading: Loading
});

const EditLanding = Loadable({
  loader: () => import('../views/EditContent/EditHome/EditLanding/EditLanding'),
  loading: Loading
});

const EditChallenge = Loadable({
  loader: () => import('../views/EditContent/EditHome/EditChallenge/EditChallenge'),
  loading: Loading
});

const EditGoals = Loadable({
  loader: () => import('../views/EditContent/EditHome/EditGoals/EditGoals'),
  loading: Loading
});

const EditGetInvolved = Loadable({
  loader: () => import('../views/EditContent/EditHome/EditGetInvolved/EditGetInvolved'),
  loading: Loading
});

const EditOurBackground = Loadable({
  loader: () => import('../views/EditContent/EditOurBackground/EditOurBackground'),
  loading: Loading
});

const EditLandingOurBackground = Loadable({
  loader: () => import('../views/EditContent/EditOurBackground/EditLanding/EditLandingOurBackground'),
  loading: Loading
});

const EditOrigin = Loadable({
  loader: () => import('../views/EditContent/EditOurBackground/EditOrigin/EditOrigin'),
  loading: Loading
});

const EditTeam = Loadable({
  loader: () => import('../views/EditContent/EditOurBackground/EditTeam/EditTeam'),
  loading: Loading
});

const EditGetInvolvedOurBackground = Loadable({
  loader: () => import('../views/EditContent/EditOurBackground/EditGetInvolved/EditGetInvolvedOurBackground'),
  loading: Loading
});

const EditContact = Loadable({
  loader: () => import('../views/EditContent/EditContact/EditContact'),
  loading: Loading
});

const EditFurtherInformation = Loadable({
  loader: () => import('../views/EditContent/EditFurtherInformation/EditFurtherInformation'),
  loading: Loading
});

const EditLandingFurtherInformation = Loadable({
  loader: () => import('../views/EditContent/EditFurtherInformation/EditLanding/EditLandingFurtherInformation'),
  loading: Loading
});

const EditInformationItems = Loadable({
  loader: () => import('../views/EditContent/EditFurtherInformation/EditInformationItems/EditInformationItems'),
  loading: Loading
});

const EditGetInvolvedFurtherInformation = Loadable({
  loader: () => import('../views/EditContent/EditFurtherInformation/EditGetInvolved/EditGetInvolvedFurtherInformation'),
  loading: Loading
});

const EditFooter = Loadable({
  loader: () => import('../views/EditContent/EditFooter/EditFooter'),
  loading: Loading
});

const ProspectsManagement = Loadable({
  loader: () => import('../views/ProspectsManagement/ProspectsManagement'),
  loading: Loading
});

const PodSummary = Loadable({
  loader: () => import('../views/PodSummary/PodSummary'),
  loading: Loading
});

const ApprovedClients = Loadable({
  loader: () => import('../views/Clients/ApprovedClients'),
  loading: Loading
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    { path: '/', name: 'Home', component: DefaultLayout, exact: true },
    { path: '/login', name: 'Login', component: Login, exact: true },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard },
    { path: '/edit-content', name: 'Edit Content', component: EditContent, exact: true },
    { path: '/edit-content/home', name: 'Home', component: EditHome, exact: true },
    { path: '/edit-content/home/landing', name: 'Landing', component: EditLanding },
    { path: '/edit-content/home/challenge', name: 'Challenge', component: EditChallenge },
    { path: '/edit-content/home/goals', name: 'Goals', component: EditGoals },
    { path: '/edit-content/home/get-involved', name: 'Get Involved', component: EditGetInvolved },
    { path: '/edit-content/our-background', name: 'Our Background', component: EditOurBackground, exact: true },
    { path: '/edit-content/our-background/landing', name: 'Landing', component: EditLandingOurBackground },
    { path: '/edit-content/our-background/origin', name: 'Origin', component: EditOrigin },
    { path: '/edit-content/our-background/team', name: 'Team', component: EditTeam },
    { path: '/edit-content/our-background/get-involved', name: 'Get Involved', component: EditGetInvolvedOurBackground },
    { path: '/edit-content/further-information', name: 'Further Information', component: EditFurtherInformation, exact: true },
    { path: '/edit-content/further-information/landing', name: 'Landing', component: EditLandingFurtherInformation },
    { path: '/edit-content/further-information/information-items', name: 'Information Items', component: EditInformationItems },
    { path: '/edit-content/further-information/get-involved', name: 'Get Involved', component: EditGetInvolvedFurtherInformation },
    { path: '/edit-content/contact', name: 'Contact', component: EditContact },
    { path: '/edit-content/footer', name: 'Footer', component: EditFooter },
    { path: '/prospects-management', name: 'Prospects Managemet', component: ProspectsManagement },
    { path: '/pod-summary', name: 'Pod Summary', component: PodSummary },
    { path: '/approved-clients', name: 'Approved Clients', component: ApprovedClients },
    //   { path: '/theme', name: 'Theme', component: Colors, exact: true },
//   { path: '/theme/colors', name: 'Colors', component: Colors },
//   { path: '/theme/typography', name: 'Typography', component: Typography },
//   { path: '/base', name: 'Base', component: Cards, exact: true },
//   { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
//   { path: '/base/cards', name: 'Cards', component: Cards },
//   { path: '/base/carousels', name: 'Carousel', component: Carousels },
//   { path: '/base/collapses', name: 'Collapse', component: Collapses },
//   { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
//   { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
//   { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
//   { path: '/base/navbars', name: 'Navbars', component: Navbars },
//   { path: '/base/navs', name: 'Navs', component: Navs },
//   { path: '/base/paginations', name: 'Paginations', component: Paginations },
//   { path: '/base/popovers', name: 'Popovers', component: Popovers },
//   { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
//   { path: '/base/switches', name: 'Switches', component: Switches },
//   { path: '/base/tabs', name: 'Tabs', component: Tabs },
//   { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
//   { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
//   { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
//   { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
//   { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
//   { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
//   { path: '/buttons/loading-buttons', name: 'Loading Buttons', component: LoadingButtons },
//   { path: '/charts', name: 'Charts', component: Charts },
//   { path: '/editors', name: 'Editors', component: CodeEditors, exact: true },
//   { path: '/editors/code-editors', name: 'Code Editors', component: CodeEditors },
//   { path: '/editors/text-editors', name: 'Text Editors', component: TextEditors },
//   { path: '/forms', name: 'Forms', component: BasicForms, exact: true },
//   { path: '/forms/advanced-forms', name: 'Advanced Forms', component: AdvancedForms },
//   { path: '/forms/basic-forms', name: 'Basic Forms', component: BasicForms },
//   { path: '/google-maps', name: 'Google Maps', component: GoogleMaps },
//   { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
//   { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
//   { path: '/icons/flags', name: 'Flags', component: Flags },
//   { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
//   { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
//   { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
//   { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
//   { path: '/notifications/badges', name: 'Badges', component: Badges },
//   { path: '/notifications/modals', name: 'Modals', component: Modals },
//   { path: '/notifications/toastr', name: 'Toastr', component: Toastr },
//   { path: '/plugins', name: 'Plugins', component: Calendar, exact: true },
//   { path: '/plugins/calendar', name: 'Calendar', component: Calendar },
//   { path: '/plugins/draggable', name: 'Draggable Cards', component: Draggable },
//   { path: '/plugins/spinners', name: 'Spinners', component: Spinners },
//   { path: '/tables', name: 'Tables', component: Tables, exact: true },
//   { path: '/tables/data-table', name: 'Data Table', component: DataTable },
//   { path: '/tables/tables', name: 'Tables', component: Tables },
//   { path: '/apps', name: 'Apps', component: Compose, exact: true },
//   { path: '/apps/email', name: 'Email', component: Compose, exact: true },
//   { path: '/apps/email/compose', name: 'Compose', component: Compose },
//   { path: '/apps/email/inbox', name: 'Inbox', component: Inbox },
//   { path: '/apps/email/message', name: 'Message', component: Message },
//   { path: '/apps/invoicing', name: 'Invoice', component: Invoice, exact: true },
//   { path: '/apps/invoicing/invoice', name: 'Invoice', component: Invoice },
//   { path: '/users', exact: true,  name: 'Users', component: Users },
//   { path: '/users/:id', exact: true, name: 'User Details', component: User }
];

export default routes;
