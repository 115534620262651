import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from 'react-redux-firebase'
import ProtectedRoute from './ProtectedRoute'
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/all.min.css';
// Import Flag Icons Set
// import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout } from './containers';

import Login from "./views/Login/Login";
import Loading from './components/Loading/Loading'
import UserConfirmation from "./components/UserConfirmation/UserConfirmation";
import ResetPassword from "./views/ResetPassword/ResetPassword";

// const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
class App extends Component {



    render() {
        // console.log("Render")
        if (this.props.auth.isLoaded) {
        return (
            <BrowserRouter getUserConfirmation={(message, callback) =>  UserConfirmation(message, callback)}>
                <Switch>
                    <Route exact path="/login" name="Login Page" component={Login} />
                    <Route exact path="/reset-password" name="Reset Password" component={ResetPassword} />
                    <ProtectedRoute path="/" name="Home" component={DefaultLayout} {...this.props}/>
                </Switch>
            </BrowserRouter>
            );
        } else {
            return <Loading/>;
        }
    }
  }

  const mapStateToProps = state => {
    return {
      auth: state.firebase.auth || {},
      user: state.auth.user || {},
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
    //   getUser: userId => dispatch(getUser(userId)),
    //   signOut: () => dispatch(signOut()),
    //   verifyAdmin: () => dispatch(verifyAdmin())
    };
  };

  export default compose(
    firebaseConnect(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(App);
