import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

var config = {
    "apiKey":"AIzaSyAYHi7Gw4buqA5vgj5CFdcje4Vuxqyu7pQ",
    "authDomain":"consciouscrops-40375.firebaseapp.com",
    "databaseURL":"https://consciouscrops-40375.firebaseio.com",
    "projectId":"consciouscrops-40375",
    "storageBucket":"consciouscrops-40375.appspot.com",
    "messagingSenderId":"1045241896728",
    "appId":"1:1045241896728:web:b6acf195644bd38763c86d",
    "measurementId":"G-FBWEC2L5FY"
}

firebase.initializeApp(config);

export default firebase;