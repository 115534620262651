export default {
    items: [
      {
        name: 'Conscious Crops CMS',
        url: '/dashboard',
        icon: 'icon-cc',
        // badge: {
        //   variant: 'info',
        //   text: 'NEW',
        // },
      },
      {
        title: true,
        name: 'Edit Content',
        wrapper: {            // optional wrapper object
          element: '',        // required valid HTML5 element tag
          attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
        },
        class: ''             // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: 'Home',
        url: '/edit-content/home',
        icon: 'icon-home',
        description: 'Edit home page content. Change text, images and video.',
        navigationFor: 'edit-content',
        children: [
            {
                name: 'Landing',
                url: '/edit-content/home/landing',
                icon: 'cil-airplay',
                description: 'Edit landing section content. Change text and video.'
              },
              {
                name: 'Challenge',
                url: '/edit-content/home/challenge',
                icon: 'icon-rocket',
                description: 'Edit challenge section content. Change text and images.'
              },
              {
                name: 'Goals',
                url: '/edit-content/home/goals',
                icon: 'icon-trophy',
                description: 'Edit goals section content. Change text and images.'
              },
              {
                name: 'Get Involved',
                url: '/edit-content/home/get-involved',
                icon: 'cil-inbox',
                description: 'Edit get involved section content. Change text and images.'
              },
        ],
      },
      {
        name: 'Our Background',
        url: '/edit-content/our-background',
        icon: 'icon-layers',
        description: 'Edit our background page content. Change text and images.',
        navigationFor: 'edit-content',
        children: [
            {
                name: 'Landing',
                url: '/edit-content/our-background/landing',
                icon: 'cil-airplay',
                description: 'Edit landing section content. Change text and image.'
              },
              {
                name: 'Origin',
                url: '/edit-content/our-background/origin',
                icon: 'cib-atom',
                description: 'Edit origin section content. Change text and images.'
              },
              {
                name: 'Team',
                url: '/edit-content/our-background/team',
                icon: 'icon-organization',
                description: 'Edit team section content. Change text and images.'
              },
              {
                name: 'Get Involved',
                url: '/edit-content/our-background/get-involved',
                icon: 'cil-inbox',
                description: 'Edit get involved section content. Change text and images.'
              },
        ],
      },
      {
        name: 'Further Information',
        url: '/edit-content/further-information',
        icon: 'cil-info',
        description: 'Edit further information page content. Change text and images.',
        navigationFor: 'edit-content',
        children: [
            {
                name: 'Landing',
                url: '/edit-content/further-information/landing',
                icon: 'cil-airplay',
                description: 'Edit landing section content. Change text and image.'
              },
              {
                name: 'Information items',
                url: '/edit-content/further-information/information-items',
                icon: 'icon-grid',
                description: 'Edit information items section content. Change text and images.'
              },
              {
                name: 'Get Involved',
                url: '/edit-content/further-information/get-involved',
                icon: 'cil-inbox',
                description: 'Edit get involved section content. Change text and images.'
              },
        ],
      },
      {
        name: 'Contact',
        url: '/edit-content/contact',
        icon: 'icon-envelope',
        navigationFor: 'edit-content',
        description: 'Edit contact page content. Form titles and options.'
      },
      {
        name: 'Footer',
        url: '/edit-content/footer',
        icon: 'cil-expand-down',
        navigationFor: 'edit-content',
        description: 'Edit footer section content. Change contact information.'
      },
      {
        divider: true,
      },
      {
        title: true,
        name: 'Prospects',
        wrapper: {            // optional wrapper object
          element: '',        // required valid HTML5 element tag
          attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
        },
        class: ''             // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: 'Prospects Management',
        url: '/prospects-management',
        icon: 'icon-people',
        description: 'Manage prospects. Contact and discard possible prospects.',
        navigationFor: 'prospects'
      },
      {
        title: true,
        name: 'Pod Updates',
        wrapper: {            // optional wrapper object
          element: '',        // required valid HTML5 element tag
          attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
        },
        class: ''             // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: 'Pod Summary',
        url: '/pod-summary',
        icon: 'icon-list',
        description: 'Manage pod updates. Add, edit or delete pod updates',
        navigationFor: 'pod'
      },
      {
        title: true,
        name: 'Clients',
        wrapper: {            // optional wrapper object
          element: '',        // required valid HTML5 element tag
          attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
        },
        class: ''             // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: 'Approved Clients',
        url: '/approved-clients',
        icon: 'icon-user-following',
        description: 'Manage pod updates. Add, edit or delete pod updates',
        navigationFor: 'clients'
      },
    //   {
    //     divider: true,
    //     class: 'm-2'
    //   },
    //   {
    //     title: true,
    //     name: 'Labels'
    //   },
    //   {
    //     name: 'Label danger',
    //     url: '',
    //     icon: 'fa fa-circle',
    //     label: {
    //       variant: 'danger'
    //     },
    //   },
    //   {
    //     name: 'Label info',
    //     url: '',
    //     icon: 'fa fa-circle',
    //     label: {
    //       variant: 'info'
    //     }
    //   },
    //   {
    //     name: 'Label warning',
    //     url: '',
    //     icon: 'fa fa-circle',
    //     label: {
    //       variant: 'warning'
    //     }
    //   },
    {
        name: 'Go to Conscious Crops',
        url: 'https://consciouscrops.us/',
        icon: 'icon-cc',
        variant: 'custom',
        class: 'mt-auto',
        attributes: {
            target: '_blank',
            rel: 'noopener'
        }
	}
    ]
  };