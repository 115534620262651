// This component replaces the default window dialog for confirming actions
// It is used in all the form components for preventing navigation when 
// the form is dirty has not been saved

import React from "react";
import ReactDOM from "react-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const UserConfirmation = (message, callback) => {
   const container = document.createElement("div");
   container.setAttribute("custom-confirmation-navigation", "");
   document.body.appendChild(container);
   const closeModal = (callbackState) => {
      ReactDOM.unmountComponentAtNode(container);
      callback(callbackState);
   };

   let modal = true;
   const toggle = () => modal = false;

   ReactDOM.render(
    <Modal isOpen={modal} toggle={() => {toggle(); closeModal(false);}} centered>
    <ModalHeader toggle={() => {toggle(); closeModal(false);}}>Are you sure want to leave without saving?</ModalHeader>
    <ModalBody>
        It looks like you might have some unsaved changes! Are you sure you want to continue?
    </ModalBody>
    <ModalFooter>
      <Button color='primary' onClick={() => {toggle(); closeModal(true)}}>Leave</Button>{' '}
      <Button color="secondary" onClick={() => {toggle(); closeModal(false);}}>Cancel</Button>
    </ModalFooter>
  </Modal>,
   container
  );
};
export default UserConfirmation;