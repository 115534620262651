import React from 'react';
import { ReactComponent as Logo } from "./icon.svg";
export default function Loading() {
    return (
        <div className='animated fadeIn' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 1018, position: 'absolute', opacity: 1, width: '100%', height: '100%', top: 0, left: 0 }}>
            <div style={{ top: '50%', left: '50%', margin: 0, position: 'absolute', transform: 'translate(-50%, -50%)' }}>
                <div className="animated infinite pulse">
                    <Logo/>
                </div>
            </div>
          <div style={{zIndex: 1018, top: '57%', left: '50%', transform: 'translate(-50%, -60%)', position: 'absolute'}}>
              <p style={{ color: 'white' }} > Loading... </p>
        </div>
    </div>
    )
}