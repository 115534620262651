import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from "react-redux";
import { firestoreConnect } from 'react-redux-firebase';
import { Container } from 'reactstrap';
import { logout } from '../../store/actions/authActions'
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../navbarConfig/_nav';
// routes config
import routes from '../../routes/routes';

import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

// Toasts
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class DefaultLayout extends Component {

    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }

      updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

  render() {
    //   console.log(this.state)
    //   console.log(this.props)
    return (
      <div className="app">
          { this.state.width < 992 ?
                <AppHeader fixed>
                <DefaultHeader />
                </AppHeader>
                    : null
          }
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes}/>
                <span className='text-muted primary  user-email' style={{ position: 'absolute', top: '.6rem', right: '4rem', padding: '0' }} >{this.props.auth.email}</span>
                <button onClick={this.props.logout} className="btn logout slide-left" style={{ position: 'absolute', top: '4rem', right: '1rem', padding: '0' }} title="Logout"> <i class="icon-logout icons"></i> </button>
            <Container fluid>   
              <Switch>
                { routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <ToastContainer position="bottom-right" autoClose={5000} />
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        auth: state.firebase.auth || {},
        content: state.firestore.ordered.content || []
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    };
  };

export default compose(
    firestoreConnect((props, state) => ['content']), // sync todos collection from Firestore into redux
    // connect((state) => ({
    //   todosList: state.firestore.data.todos
    // }),
    connect(
    mapStateToProps,
    mapDispatchToProps
  ))(DefaultLayout);
