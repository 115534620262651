import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { connect } from "react-redux";
import { signIn, sendResetPasswordEmail } from "../../store/actions/authActions";
import { compose } from "redux";
import { ReactComponent as Logo } from "../../assets/img/brand/logo_footer.svg";
import Modal from "../../components/Modal/Modal";

// Toasts
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Login extends Component {
    state = {
        email: '',
        password: '',
        canLogin: false,
        emailRecoverPassword: '',
        emailValid: false
    }

    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // if(e.target.type === "email"){
          if (emailRex.test(e)) {
            return true;
          } else {
            return false;
          }
        // }
        // retu/rn true;
      }

    // ------------------- LOGIN --------------------- //
    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.value,
        }, () => {
            this.setState({
                canLogin: this.validateEmail(this.state.email) && this.state.password !== ''
            })
        });
    };

    handleSubmit = e => {
        // console.log('hello');
        this.props.signIn(this.state);
      };

      onKeyDown = event => {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();
          this.handleSubmit();
        }
      };

      // -------------------- RECOVER PASSWORD ------------------------ //

      sendEmail = () => {
        // console.log(this.state)
        this.props.sendResetPasswordEmail(this.state.emailRecoverPassword);
      }

      handleChangeRecoverPassword = e => {
            this.setState({
                [e.target.id]: e.target.value,
            }, () => {
                this.setState({
                    emailValid: this.state.emailRecoverPassword !== '' && this.validateEmail(this.state.emailRecoverPassword)
                })
            });
        };

      onKeyDownRecoverPassword = event => {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();
          this.sendEmail();
        }
      };

      recoverPasswordTemplate = () => {
          return (
              <div  className='p-3'>
                  <Row>
                      <Col xs="12" className="mb-2">
                            Enter your email to recover your password:
                      </Col>
                      <Col xs="12">
                        <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="cil-at"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            id="emailRecoverPassword"
                            type="email"
                            placeholder="Email"
                            onKeyDown={this.onKeyDownRecoverPassword}
                            autoComplete="username"
                            onChange={this.handleChangeRecoverPassword}
                            />
                        </InputGroup>
                      </Col>
                  </Row>
                  
              </div>
          )
      }

  render() {

    if (!this.props.auth.isEmpty) {
        let { from } = this.props.location.state || { from: { pathname: "/" } };
        return <Redirect to={from} />;
      }

    return (
      <div className="app flex-row align-items-center" style={{backgroundColor: 'white'}}>
        <Container>
          <Row className="justify-content-center">
              <Col xs="12" className="d-flex flex-column justify-content-center mb-2">
                    {/* <img src="../assets/img/brand/logo.svg"/> */}
                    <Logo style={{ height: '100px' }}/>
                    <p className='text-center cms mt-1'>CMS</p>
              </Col>
            <Col md="4">
              <CardGroup>
                <Card className="p-4" style={{ boxShadow: '0 0 25px rgba(87,102,113,.3)' }}>
                  <CardBody>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="cil-at"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                          id="email"
                          type="text"
                          placeholder="Email"
                          onKeyDown={this.onKeyDown}
                          autoComplete="username"
                          onChange={this.handleChange}
                        />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                          id="password"
                          type="password"
                          placeholder="Password"
                          onKeyDown={this.onKeyDown}
                          autoComplete="current-password"
                          onChange={this.handleChange}
                        />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4" onClick={this.handleSubmit} disabled={!this.state.canLogin}>Login</Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        {/* <Button color="link" className="px-0">Forgot password?</Button> */}
                        <Modal centered colorOkButton={'primary'} disabledOkButton={!this.state.emailValid} buttonLabel={'Forgot password?'} buttonColor={'link'} classNameButton={'px-0'} title={'Recover password'} body={this.recoverPasswordTemplate} okLabel={'Send'} action={this.sendEmail}/>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <ToastContainer position="bottom-right" autoClose={5000} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
      authError: state.auth.authError,
      auth: state.firebase.auth || {},
    };
  };


  const mapDispatchToProps = dispatch => {
    return {
      signIn: creds => dispatch(signIn(creds)),
      sendResetPasswordEmail: email => dispatch(sendResetPasswordEmail(email))
    };
  };

export default compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
  )(Login);
