const initState = {
    authError: null,
    user: null,
    isAdmin: false,
    emailTaken: false
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                user: action.user,
                authError: null
            };
        case 'LOGIN_ERROR':
            return {
                ...state,
                authError: action.message
            };
        case 'LOGOUT_SUCCESS':
            return {
                ...state,
                user: null,
                authError: null
            };
        case 'LOGOUT_ERROR':
            return {
                ...state
            };

        default:
            return state;
    }
}

export default authReducer