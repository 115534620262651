import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, Row } from 'reactstrap';
import { connect } from "react-redux";
import { resetPassword } from "../../store/actions/authActions";
import { compose } from "redux";
import { ReactComponent as Logo } from "../../assets/img/brand/logo_footer.svg";

// Toasts
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
class ResetPassword extends Component {
    state = {
        password: '',
        confirmPassword: '',
        canResetPassword: false,
        code: this.props.location.search.substring(28).split('&')[0]
    }

    // componentDidMount() {
    //     // const code = this.props.location.search.substring(28);
    //     console.log(this.state.code);
    // }

    // ------------------- ResetPassword --------------------- //
    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.value,
        }, () => {
            this.setState({
                canResetPassword: this.state.confirmPassword !== '' && this.state.password !== '' && this.state.password === this.state.confirmPassword
            })
            // console.log(this.state)
        });
    };

    handleSubmit = e => {
        // console.log('hello');
        // console.log(this.state);
        this.props.resetPassword(this.state.code, this.state.password);
        this.props.history.push('/login');
      };

      onKeyDown = event => {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();
          this.handleSubmit();
        }
      };

  render() {

    if (!this.props.auth.isEmpty) {
        let { from } = this.props.location.state || { from: { pathname: "/" } };
        return <Redirect to={from} />;
      }

    return (
      <div className="app flex-row align-items-center" style={{backgroundColor: 'white'}}>
        <Container>
          <Row className="justify-content-center">
              <Col xs="12" className="d-flex flex-column justify-content-center mb-4">
                    {/* <img src="../assets/img/brand/logo.svg"/> */}
                    <Logo style={{ height: '100px' }}/>
                    {/* <p className='text-center cms mt-1'>CMS</p> */}
              </Col>
            <Col md="4">
              <CardGroup>
                <Card className="p-4" style={{ boxShadow: '0 0 25px rgba(87,102,113,.3)' }}>
                  <CardBody>
                    <h3 className='mb-4'>Reset Password</h3>
                    <InputGroup className="mb-3">
                      <Input 
                          id="password"
                          type="password"
                          placeholder="Enter new password"
                          onKeyDown={this.onKeyDown}
                          autoComplete="current-password"
                          onChange={this.handleChange}
                        />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <Input
                          id="confirmPassword"
                          type="password"
                          placeholder="Confirm password"
                          onKeyDown={this.onKeyDown}
                          autoComplete="current-password"
                          onChange={this.handleChange}
                        />
                    </InputGroup>
                    <Row>
                      <Col xs="12">
                        <Button color="primary" className="px-4" onClick={this.handleSubmit} disabled={!this.state.canResetPassword}>Reset password</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          {/* <ToastContainer position="bottom-right" autoClose={5000} /> */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
      authError: state.auth.authError,
      auth: state.firebase.auth || {},
    };
  };


  const mapDispatchToProps = dispatch => {
    return {
        resetPassword: (code, newPassword) => dispatch(resetPassword(code, newPassword))
    };
  };

export default compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
  )(ResetPassword);
