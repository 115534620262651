import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalComponent = (props) => {
  const {
    buttonLabel,
    buttonColor,
    buttonStyles,
    classNameButton,
    className,
    title,
    body,
    okLabel,
    cancelLabel,
    centered,
    colorOkButton,
    disabledOkButton,
    action
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <Button style={{ ...buttonStyles }} color={buttonColor} className={classNameButton} onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} className={className} centered={centered}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody tag={body}>
        </ModalBody>
        <ModalFooter>
          <Button color={colorOkButton} onClick={() => {action(); toggle()}} disabled={disabledOkButton}>{okLabel ? okLabel : 'Ok'}</Button>{' '}
          <Button color="secondary" onClick={toggle}>{cancelLabel ? cancelLabel : 'Cancel'}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalComponent;